import { createEndpoint, getBodyAsJson } from '@oracle-cx-commerce/endpoints/factory';
import { populateError } from '@oracle-cx-commerce/endpoints/utils';

export const processInput = ({ text, maxResults, searchKey, searchServicePath }) => {
  return {
    params: [searchServicePath],
    query: { Ntt: text, Ntk: searchKey, Nrpp: maxResults }
  };
};

export const processOutput = async (response, _, { text, searchKey }) => {
  const json = await getBodyAsJson(response);

  const state = response.ok
    ? {
        searchRepository: {
          typeaheadSearchResults: {
            [searchKey.toLowerCase()]: { term: text, ...json }
          }
        }
      }
    : populateError(response, json);

  return state;
};

export default createEndpoint('assemblerPages', {
  processInput,
  processOutput
});
